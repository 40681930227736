import { useFormik } from "formik";
import { changePasswordValidationSchema } from "../../utils/schema";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-query";
import { changeUserPasswordAPI, logoutAPI } from "../../services/api";
import * as PR from "../../prime-modules/index";
import { useToast } from "../../context/ToastContext";
import { useTranslationContext } from "../../context/TranslationContext";
import { logoutHandler } from "./helper";

const ChangePassword = ({ setShowChangePassword }) => {
  const dispatch = useDispatch();
    const { userId } = useSelector((state) => state.auth.userData);
    const {labelsIot, validationsIot: validations } = useTranslationContext();
    const { showToast, showMessage } = useToast()
    const { mutate, isLoading } = useMutation(changeUserPasswordAPI, {
      onSuccess: (o) => {
        setShowChangePassword(false);
        showToast("success",labelsIot?.LBLI0144)
        logoutAPI().then(()=>{
          logoutHandler(dispatch);
        }).catch(e=>{
          showMessage(e?.message)
        })
      },
      onError: (o) => {
        showToast("error", o?.message)
      }
    });

    const handleSubmit = (values) => {
      const obj = {
        newPassword: values.confirmPassword,
        password: values.currentPassword,
        userId,
      };
      mutate({ ...obj });
    };

    const initialValues = {
      currentPassword: "",
      password: "",
      confirmPassword: "",
    };
    const formik = useFormik({
      initialValues,
      validationSchema: changePasswordValidationSchema,
      onSubmit: handleSubmit,
    });
  return (
      <form onSubmit={formik.handleSubmit} autoComplete="off" className="form-block">
        <div className="grid device-section-wrapper">
          <div className="col-12">
            <div className="card flex flex-column gap-2 mb-1">
              <label htmlFor="currentPassword">{labelsIot?.LBLI0091}</label>
                <PR.Password
                  label={labelsIot?.LBLI0091}
                  name={"currentPassword"}
                  type={"password"}
                  value={formik.values.currentPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  feedback={false}
                  toggleMask
                  placeholder="*******************" 
                />
            </div>
          </div>
          <div className="col-12 md:col-12">
            <div className="card flex flex-column gap-2 mb-1">
              <label htmlFor="password">{labelsIot?.LBLI0074}</label>
                <PR.Password
                  label={labelsIot?.LBLI0074}
                  name={"password"}
                  type={"password"}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  feedback={false}
                  toggleMask
                  placeholder="*******************"
                />
                {formik.touched.password && formik.errors.password && <div className="err-msg">{validations[formik.errors.password]}</div>}
              </div>
          </div>
          <div className="col-12 md:col-12">
            <div className="card flex flex-column gap-2 mb-1">
              <label htmlFor="confirmPassword">{labelsIot?.LBLI0075}</label>
                <PR.Password
                  name={"confirmPassword"}
                  type={"password"}
                  label={labelsIot?.LBLI0075}
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  feedback={false}
                  toggleMask
                  placeholder="*******************"
                />
                {formik.touched.confirmPassword && formik.errors.confirmPassword && <div className="err-msg">{validations[formik.errors.confirmPassword]}</div>}
            </div>
          </div>
          <div className="card">
            {/* {error && (
              <span className="err-msg">{showMessage(error.message)}</span>
            )} */}
          </div>
          <div className="col-12">
            <PR.Button
              label={labelsIot?.LBLI0092}
              type="submit"
              className="device-confirm-button mt-2"
              disabled={isLoading}
            />
          </div>
        </div>
      </form>
  );
};

export default ChangePassword;
