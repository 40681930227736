import React, { useEffect, useRef, useState } from "react";
import "./index.scss";
import Header from "../../../components/layout/header";
import { Formik, Form } from "formik";
import * as PR from "../../../prime-modules/index";
import { User, Password, Mail } from "../../../assets/images";
import { useMutation } from "react-query";
import { handleSignup } from "../../../services/api";
import { signUpValidationSchema } from "../../../utils/schema";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../components/form/Input";
import { storeIccid } from "../../../store/app.slice";
import { useToast } from "../../../context/ToastContext";
import GoogleAndAppleLoginButtons from "../../../components/auth/GoogleAndAppleLoginButtons";
import { useTranslationContext } from "../../../context/TranslationContext";
import {  useLocation, useNavigate } from "react-router-dom";
import Footer from "../../../components/layout/footer";

const SignUp = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { showToast } = useToast()
  const dispatch = useDispatch()
  const { isAuthenticated } = useSelector((state) => state.auth);
  const [iccid, setIccid] = useState(!!state?.iccid ? state?.iccid : '');
  const [iccidError, setIccidError] = useState("")
  const { labelsIot, validationsIot: validations } = useTranslationContext()
  const formikRef = useRef(null);

  useEffect(() => {
    if(isAuthenticated){
      navigate("/dashboard")
    }
  }, [isAuthenticated, navigate])

  const { mutate, isLoading } = useMutation(handleSignup, {
    onSuccess: (o) => {
      dispatch(storeIccid(iccid))
    showToast("success", "SIGNUP-07")
      setIccidError("")
      formikRef.current.resetForm();
      navigate("/")
    },
    onError: (o) => showToast("error", o?.message)
  })

  const initialValues = {
    firstName: "",
    email: "",
    password: "",
  }

  const handleOnBlur = ()=>{
    if(!iccid){
      setIccidError("VLDN0037")
      return false
    }
    if(iccid.length < 18){
      setIccidError("VLDN0042")
      return false
    }
    setIccidError("");
    return true
  }

  return (
    <>
      <section className="auth-wrapper">
        <Header iccid={iccid} />
        <div className="form-wrapper">
          <div className="grid grid-nogutter align-items-center w-12">
            <div className="col-12 md:col-6 left-block">
              <h1>{labelsIot?.LBLI0017}</h1>
              <div className="card flex align-items-start mt-5">
                <span className="device-label">{labelsIot?.LBLI0018}</span>
                <div className="w-12">
                  <PR.InputText
                    invalid={!!iccidError}
                    value={iccid}
                    placeholder={labelsIot?.LBLI0035}
                    maxLength={22}
                    onBlur={handleOnBlur}
                    onChange={(e) => {
                      setIccid(e.target.value)
                      setIccidError("")
                    }}
                    keyfilter={"num"}
                    className={`w-12 ${iccidError ? 'invalid-class' : 'valid-class'}`}
                    useGrouping={false}
                  />
                  {!!iccidError && <span className="err-msg">{validations[iccidError]}</span>}
                </div>
              </div>
            </div>
            <div className="col-12 md:col-6">
              <div className="form-block">
                <Formik innerRef={formikRef} initialValues={initialValues}
                  validationSchema={signUpValidationSchema}
                  onSubmit={(values, { setSubmitting }) => {
                   if(handleOnBlur()) {
                     values.iccid = iccid?.toString();
                    mutate(values);
                    setSubmitting(false);
                   }
                  }}>
                  <Form autoComplete="off">
                    <div className="card flex flex-column relative">
                      <div className="grid">
                        <div className="col-12">
                          <Input
                            label={""}
                            name={"firstName"}
                            type={"text"}
                            aria-describedby={"userName"}
                            placeholder={labelsIot?.LBLI0122}
                          />
                          <PR.Image src={User} alt="Mail" className="icon" />
                        </div>
                      </div>
                    </div>
                    <div className="card flex flex-column relative mb-2">
                      <Input
                        label={""}
                        name={"email"}
                        type={"email"}
                        aria-describedby={"email"}
                        placeholder={labelsIot?.LBLI0010}
                      />
                      <PR.Image src={Mail} alt="Mail" className="icon" />
                    </div>
                    <div className="card flex flex-column relative gap-2">
                      <Input
                        label={""}
                        name={"password"}
                        type={"password"}
                        aria-describedby={"password"}
                        placeholder={labelsIot?.LBLI0009}
                        feedback={false}
                        toggleMask
                      />
                      <PR.Image src={Password} alt="Mail" className="icon" />
                    </div>
                    <div className="card flex flex-column">
                      <PR.Button disabled={isLoading} label={labelsIot?.LBLI0003} className="login-button" type="submit" />
                    </div>
                    <GoogleAndAppleLoginButtons iccid={iccid} iccidCheck={handleOnBlur} />
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </section>
    </>
  );
};

export default SignUp;