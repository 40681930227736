import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { storeIccid } from "../store/app.slice";

// PublicRoute Component
export const PublicRoute = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const searchParams = new URLSearchParams(location.search);
  const getIccid = searchParams.get('iccid');

  useEffect(() => {
    if (getIccid) {
      dispatch(storeIccid(getIccid));
      navigate('/signup', {state: {iccid:getIccid}})
    }
  }, [dispatch, getIccid, navigate])

  const { isAuthenticated } = useSelector((state) => state.auth);
  const {iccid} = useSelector(state => state?.app)
  return isAuthenticated ? (iccid ? <Navigate to="/plans" /> : <Navigate to="/dashboard" />) : <Outlet />;
};

// PrivateRoute Component
export const PrivateRoute = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};
