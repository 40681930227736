import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import * as PR from "../../prime-modules/index";
import { useTranslationContext } from '../../context/TranslationContext'
import { dashboardIcon6 } from "../../assets/images";
import { globalConfig } from "../../services/api";

const Footer = () => {
  const { labelsIot } = useTranslationContext()
  const { pathname } = useLocation()
  const location = useLocation();
  const showSpecialSection = ['/', '/signup', '/forgot-password', '/reset-password', '/terms-and-conditions', '/privacy-policy'].includes(location.pathname);
  return (
    <>
      <footer>
        <div className='flex align-items-center justify-content-between mobile-flex'>
          {!showSpecialSection && (
            <div className='pt-4 plans-footer'>
              <p>{labelsIot?.LBLI0117} <Link target='_blank' to={'/terms-and-conditions'}>{labelsIot?.LBLI0118}</Link></p>
            </div>
          )}
          {pathname === "/plans" && <Link to="/dashboard" className='cursor-pointer'>{labelsIot?.LBLI0014} <i className="pi pi-arrow-right cursor-pointer"></i></Link>}
          {showSpecialSection && (
            <>
              <div className="special-section">
                <p>©{new Date().getFullYear()} {labelsIot?.LBLI0124} | {labelsIot?.LBLI0125} <Link target='_blank' to={'https://www.bluehouse-technology.co.uk/'}>{labelsIot?.LBLI0126}</Link> & <Link target='_blank' to={'https://www.cobira.co/'}>{labelsIot?.LBLI0146}</Link></p>
              </div>
              <div className="support-badge">
                <Link to={`mailto:${globalConfig.supportEmail}`} rel="noopener noreferrer">
                  <div className="flex align-items-center gap-2">
                      <PR.Image src={dashboardIcon6} alt="Contact Support" />
                      <span className="label">{labelsIot?.LBLI0088}</span>
                  </div>
                </Link>
              </div>
            </>
          )}
        </div>
      </footer>
    </>
  )
}
export default Footer