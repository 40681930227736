import { configureStore } from "@reduxjs/toolkit";
import auth from "./auth.slice";
import app from "./app.slice"
import language from "./language.slice";

const store = configureStore({
    reducer:{
        auth,
        app,
        language
    }
});

export default store