import { createSlice } from "@reduxjs/toolkit";
import { USER_SESSION_DATA } from "../utils/localstore";



const userData = JSON.parse(localStorage.getItem(USER_SESSION_DATA) ?? null);
const isAuthenticated = !!userData;


const initialState = { isAuthenticated, userData }

const authSlice = createSlice({
    name:'authentication',
    initialState,
    reducers:{
        login(state,{payload}){
            state.isAuthenticated  = true;
            state.userData = payload;
            localStorage.setItem(USER_SESSION_DATA,JSON.stringify(payload))
        },
        updateUserData(state,{payload}){
            state.userData = payload;
            localStorage.setItem(USER_SESSION_DATA,JSON.stringify(payload))
        },
        logout(state){
            state.isAuthenticated  = false;
            state.userData = {};
            localStorage.removeItem(USER_SESSION_DATA)
        }
       }
});
export const { logout, login, updateUserData} = authSlice.actions
export default authSlice.reducer