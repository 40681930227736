import React from "react";
import { useNavigate,useLocation } from "react-router-dom";
import Header from "../../../components/layout/header";
import { Formik, Form } from "formik";
import * as PR from "../../../prime-modules/index";
import { Password } from "../../../assets/images";
import { useMutation } from "react-query";
import { handleResetPasswordAPI } from "../../../services/api";
import { resetValidationSchema } from "../../../utils/schema";
import Input from "../../../components/form/Input";

import { useTranslationContext } from "../../../context/TranslationContext";
import { useToast } from "../../../context/ToastContext";
import Footer from "../../../components/layout/footer";

const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(location.search);
  const passwordResetUUID = queryParams.get('id');
  const {showToast} = useToast()
  const {labelsIot} = useTranslationContext()

  const { mutate, isLoading } = useMutation(handleResetPasswordAPI, {
    onSuccess: o => { 
      showToast("success","RSTPWD-03")
      navigate("/")
     },
    onError: o => showToast("error",o?.message)
  });

  const initialValues = {
    password: "",
    confirmPassword:""
  }

  const handleSubmitHandler = (values, { setSubmitting }) => {
    let params={
      password:values.password,
      passwordResetUUID
    }
    mutate(params);
    setSubmitting(false);
  }

  return (
    <>
      <section className="auth-wrapper">
        <Header />
        <div className="form-wrapper screen-height">
          <div className="grid grid-nogutter align-items-center w-12">
            <div className="col-12 md:col-6 left-block">
              <h1>{labelsIot?.LBLI0072}</h1>
              <h2>{labelsIot?.LBLI0073}</h2>
            </div>
            <div className="col-12 md:col-6">
              <div className="form-block">
                <Formik
                  initialValues={initialValues}
                  validationSchema={resetValidationSchema}
                  onSubmit={handleSubmitHandler}>
                  <Form autoComplete="off">
                    <div className="card flex flex-column relative gap-2">
                      <Input
                        label=""
                        name="password"
                        type="password"
                        feedback={false}
                        aria-describedby="password"
                        toggleMask
                        placeholder={labelsIot?.LBLI0074}
                      />
                      <PR.Image src={Password} alt="Mail" className="icon" />
                    </div>
                    <div className="card flex flex-column relative gap-2">
                      <Input
                        label=""
                        name="confirmPassword"
                        type="password"
                        feedback={false}
                        aria-describedby="password"
                        toggleMask
                        placeholder={labelsIot?.LBLI0075}
                      />
                      <PR.Image src={Password} alt="Mail" className="icon" />
                    </div>
                    <div className="card flex flex-column">
                      <PR.Button disabled={isLoading} type="submit" label={labelsIot?.LBLI0076} className="login-button" />
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <Footer/> 
      </section>
    </>
  );
};

export default ResetPassword;