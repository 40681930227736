import React from 'react'
import Header from "../../components/layout/header";
import * as PR from "../../prime-modules/index";
import { useTranslationContext } from "../../context/TranslationContext";
import { CURRENT_PAYMENT_PLAN } from '../../utils/localstore';
import { getPlanDescription, setPrice } from '../../utils/reuse';
import Footer from '../../components/layout/footer';
import { Checkmark } from '../../assets/images';

const PaymentProvider = ({children, isLoading}) => {
  const {labelsIot} = useTranslationContext()
  const currentPaymentPlan = JSON.parse(localStorage.getItem(CURRENT_PAYMENT_PLAN))

  return ( 
        <section className="auth-wrapper">
          <Header />
          <div className="payment-section flex flex-column justify-content-center">
            <div className="grid grid-nogutter align-items-center w-12">
              <div className="col-12 md:col-3 mobile-full-width">
                <div className="plan-block">
                  <div className="bundle-box">
                    {currentPaymentPlan?.label ?<div className="popular-tag">
                      <i className="pi pi-star-fill"></i>
                      <span>{currentPaymentPlan?.label}</span>
                    </div>:null}
                    <h2>{currentPaymentPlan?.description}</h2>
                    <h3>
                      {setPrice(currentPaymentPlan?.price)}
                    </h3>
                    <p>{labelsIot?.LBLI0013} {getPlanDescription(currentPaymentPlan?.description)}</p>
                    <div className="flex align-items-center justify-content-center gap-2 mt-5">
                      <PR.Image
                        src={Checkmark}
                        alt="Checkmark"
                        className="icon"
                      />
                      <text className="select-info">
                        {labelsIot?.LBLI0095}
                      </text>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 md:col-9 mobile-full-width">
                <div className="payment-methods">
                  <div className="payment-tabs">
                    <div className='payment-form'>
                      {isLoading && (
                        <div className="loading-section payment-load">
                          <i className="pi pi-spin pi-spinner mr-2"></i> {labelsIot?.LBLI0139}
                        </div>
                      )}
                      {children}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
                  <Footer />
        </section>
     );
}
 
export default React.memo(PaymentProvider);