import React, { useCallback, useEffect } from "react";
import "./index.scss";
import { useLocation, useNavigate } from "react-router-dom";
import PaymentOptions from "./PaymentOptions";
import { envConfig } from "../../constants";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { addressElementOptions } from "./helper";
import { confirmSubscriptionPaymentAPI } from "../../services/api";
import { useMutation } from "react-query";
import { useToast } from "../../context/ToastContext";
import PaymentProvider from "./PaymentProvider";

export const stripePromise = loadStripe(envConfig.REACT_APP_STRIPE_PUBLIC_KEY);

const Payment = () => {
  const navigate = useNavigate()
  const { showToast } = useToast();
  const { state } = useLocation();

    useEffect(() => {
      if(!state?.sessionClientSecret || !state?.orderID) {
        navigate("/");
      }
    }, [navigate, state?.orderID, state?.sessionClientSecret])

    const {mutate, isLoading } = useMutation(confirmSubscriptionPaymentAPI, {
      onSuccess: payRes => {
       
        navigate("/payment-success", {state: {payRes},replace:true});
        //  storeIccid("");
      },
      onError: er => showToast("error", er?.message) 
    });

    const confirmPaymentHandler = useCallback(() => {
      const queryParams = "orderid=" + state?.orderID + "&random_id=" + state?.randomId;
      mutate(queryParams);
    }, [mutate, state?.orderID, state?.randomId]);

    return (
      <PaymentProvider isLoading={isLoading}>
        <Elements
        stripe={stripePromise}
        options={{
          ...addressElementOptions,
          locale: localStorage.getItem("i18nextLng"),
        }}
      >
        <PaymentOptions
          sessionClientSecret={state?.sessionClientSecret}
          price={state?.price}
          confirmPaymentHandler={confirmPaymentHandler}
        />
      </Elements>
      {/* <PaymentSuccess/> */}
      </PaymentProvider>
    );
};

export default Payment;