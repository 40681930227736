import React from "react";
import Header from "../../components/layout/header";
import Footer from "../../components/layout/footer";
import PrivacyPolicy from "../dashboard/PrivacyPolicy";
import "./index.scss";

const PrivacyPolicyPage = ({}) => {
  return (
    <>
        <Header/>
            <section className="terms-and-conditions-section">
                <PrivacyPolicy/>
            </section>
        <div className="footer-block">
            <Footer/>
        </div>
    </>
  );
};

export default PrivacyPolicyPage;