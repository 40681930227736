import React from "react";
import Header from "../../../components/layout/header";
import { Formik, Form } from "formik";
import * as PR from "../../../prime-modules/index";
import { Mail } from "../../../assets/images";
import { useMutation } from "react-query";
import { forgotPassword } from "../../../services/api";
import { forgotPasswordValidationSchema } from "../../../utils/schema";
import Input from "../../../components/form/Input";
import { useTranslationContext } from "../../../context/TranslationContext";
import { useToast } from "../../../context/ToastContext";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../../components/layout/footer";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const {showToast} = useToast()
  const {labelsIot} = useTranslationContext()

  const { mutate, isLoading } = useMutation(forgotPassword, {
    onSuccess: o => { 
      showToast("success",o)
      navigate("/")
       },
    onError: o => showToast("error",o?.message)
  });

  const initialValues = {
    email: "",
  }

  const loginSubmitHandler = (values, { setSubmitting }) => {
    mutate(values?.email);
    setSubmitting(false);
  }

  return (
    <>
      <section className="auth-wrapper">
        <Header />
        <div className="form-wrapper screen-height">
          <div className="grid grid-nogutter align-items-center w-12">
            <div className="col-12 md:col-6 left-block">
              <h1>{labelsIot?.LBLI0054}</h1>
              <h2>{labelsIot?.LBLI0055}</h2>
            </div>
            <div className="col-12 md:col-6">
              <div className="form-block">
                <Formik
                  initialValues={initialValues}
                  validationSchema={forgotPasswordValidationSchema}
                  onSubmit={loginSubmitHandler}>
                  <Form autoComplete="off">
                    <div className="card flex flex-column relative">
                      <Input
                        label=""
                        name="email"
                        type="email"
                        aria-describedby="email"
                        placeholder={labelsIot?.LBLI0053}
                      />
                      <PR.Image src={Mail} alt="Mail" className="icon" />
                    </div>
                    <div className="card flex flex-column">
                      <PR.Button disabled={isLoading} type="submit" label={labelsIot?.LBLI0052} className="login-button" />
                    </div>
                    <div className="card text-center mt-4">
                        <p>{labelsIot?.LBLI0056} <Link to={'/'}>{labelsIot?.LBLI0003}</Link></p>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </section>
    </>
  );
};

export default ForgotPassword;