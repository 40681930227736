import './index.scss'
import { useNavigate } from 'react-router-dom';
import * as PR from "../../prime-modules/index";
import { useTranslationContext } from '../../context/TranslationContext';

const NotFoundPage = () =>{
    const navigate = useNavigate()
    const {labelsIot} = useTranslationContext()
    return (
        <>
            <div className="page-404-section flex flex-column justify-content-center">
                <div className='container'>
                    <div className='grid custom-margin'>
                        <div className="col-12 lg:col-6">
                            <span className='error-span'>{labelsIot?.LBLI0057}</span>
                            <h1>{labelsIot?.LBLI0058}</h1>
                            <p>{labelsIot?.LBLI0059}</p>
                            <PR.Button label={"Back"} onClick={navigate.bind(null,"/")} type='submit' className="goto-button w-12"/>
                        </div>
                        <div className="col-12 lg:col-6 text-center">
                            <PR.Image src={require("../../assets/images/404.png")} alt="404 error" className='error-image'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default NotFoundPage