import React, { useState } from "react";
import * as PR from "../../prime-modules/index";
import { Link, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import {
  card_Icon
} from "../../assets/images";
import { useSelector } from "react-redux";
import {
  AddressElement,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { envConfig } from "../../constants";
import {
  addressOptions,
  cardNumberstyles,
} from "./helper";
import { getPaymentDescription, setPrice, showDate } from "../../utils/reuse";
import { useToast } from "../../context/ToastContext";
import { useTranslationContext } from "../../context/TranslationContext";
import { CURRENT_PAYMENT_PLAN } from "../../utils/localstore";

export const stripePromise = loadStripe(envConfig.REACT_APP_STRIPE_PUBLIC_KEY);

const PaymentOptions = ({ sessionClientSecret, price, confirmPaymentHandler }) => {
  const currentPaymentPlan = JSON.parse(localStorage.getItem(CURRENT_PAYMENT_PLAN))
  const { userData } = useSelector((state) => state.auth);
  const { labelsIot, validationsIot } = useTranslationContext()
  const stripe = useStripe();
  const elements = useElements();
  const { state } = useLocation();
  const { showToast } = useToast();

  const [stripeError, setStripeError] = useState("");
  const [stripeAddressError, setStripeAddressError] = useState("");
  const [billingDetails, setBillingDetails] = useState({});
  //const [paymentOptions, setPaymentOptions] = useState({});
  const [cardError, setCardError] = useState("")

  const paymentCard = (options) => {
    return (
      <div
        className="tab flex align-items-center gap-2"
        onClick={options.onClick}
      >
        <PR.Image src={card_Icon} alt="Card" />
        <span className="">{labelsIot?.LBLI0116}</span>
      </div>
    );
  };
  // const paymentPaypal = (options) => {
  //   return (
  //     <div className="tab" onClick={options.onClick}>
  //       <PR.Image src={paypal_Icon} alt="Paypal" />
  //     </div>
  //   );
  // };
  // const paymentGPay = (options) => {
  //   return (
  //     <div className="tab" onClick={options.onClick}>
  //       <PR.Image src={gpay_Icon} alt="GPay" />
  //     </div>
  //   );
  // };
  // const paymentApplePay = (options) => {
  //   return (
  //     <div className="tab" onClick={options.onClick}>
  //       <PR.Image src={applepay_Icon} alt="Apple Pay" />
  //     </div>
  //   );
  // };

  const addressChangeHandler = (event) => {
    setStripeAddressError("");
    if (event.complete) {
      setBillingDetails(event.value);
    } else {
      setBillingDetails({});
    }
  };

  const onCardChange = (e) => {
    setCardError("")
    if (!e.error && stripeError) setStripeError("");
  };

  const payNowHandler = async () => {
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardNumberElement);

    if(!!billingDetails?.address) {
      const { error, paymentIntent } = await stripe.confirmCardPayment(sessionClientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: billingDetails
        },
      });
      if(error) {
        formik.setSubmitting(false);
        setCardError(error.message)
      } else if (paymentIntent) {
        confirmPaymentHandler()
      } else {
        formik.setSubmitting(false);
        showToast("error", "GNRMSG-07");
      }
    } else {
      setStripeAddressError(validationsIot?.VLDN0017)
    }
  }

  const formik = useFormik({
    initialValues: { email: userData?.email },
    onSubmit: payNowHandler,
    enableReinitialize: true,
  });

  // useEffect(() => {
  //   if (!!stripe && !!elements) {
  //     const pr = stripe.paymentRequest(prOptions(price));
  //     pr?.canMakePayment().then((result) => {
  //       setPaymentOptions(result);
  //     });
  //   }
  // }, [elements, price, stripe]);

  const defaultValues = {
    name: userData.firstName,
  };

  return (
    <>
      <h2>{labelsIot?.LBLI0104}</h2>
      <PR.TabView>
        <PR.TabPanel headerTemplate={paymentCard}>
          <div className="card-section">
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
              <div className="grid w-12">
                <div className="col-12 md:col-6">
                  <h3>{labelsIot?.LBLI0105}</h3>
                  <div className="card flex flex-column mb-4">
                    <PR.InputText
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder={labelsIot?.LBLI0082}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div>{formik.errors.email}</div>
                    )}
                  </div>
                  <AddressElement
                    onChange={addressChangeHandler}
                    options={{ ...addressOptions, defaultValues }}
                  />
                  {stripeAddressError && (
                    <div className="err-msg">{stripeAddressError}</div>
                  )}
                </div>
                <div className="col-12 md:col-6">
                  <h3>{labelsIot?.LBLI0106}</h3>
                  <div className="flex flex-wrap align-items-center mb-4 relative">
                    <CardNumberElement
                      options={{ style: { base: cardNumberstyles } }}
                      className="p-inputtext cardInput"
                      onChange={onCardChange}
                    />
                  </div>
                  <div className="pb-5">
                    <div className="flex align-items-center  gap-3">
                      <CardExpiryElement
                        options={{ style: { base: cardNumberstyles } }}
                        className="p-inputtext cardInput"
                        onChange={onCardChange}
                      />
                      <CardCvcElement
                        options={{ style: { base: cardNumberstyles } }}
                        className="p-inputtext cardInput"
                        onChange={onCardChange}
                      />
                    </div>
                    {cardError && (
                    <div className="err-msg">{cardError}</div>
                  )}
                  </div>
                  
                  <div className="card flex flex-column mb-4">
                    <p>
                      {labelsIot?.LBLI0112} <strong>{setPrice(price)}</strong> {labelsIot?.LBLI0113} <strong>{getPaymentDescription(currentPaymentPlan?.description)}</strong> {labelsIot?.LBLI0115} <strong>{showDate(state?.subscriptionNextDue)}</strong>
                    </p>
                  </div>
                  <div className="card flex flex-column">
                    <PR.Button
                      label={labelsIot?.LBLI0107}
                      type="submit"
                      className="confirm-button"
                      disabled={formik.isSubmitting}
                    />
                  </div>
                  <div className="card flex flex-column mt-4">
                    <p className="small_p">
                     {labelsIot?.LBLI0108} <Link target='_blank' to="/terms-and-conditions">{labelsIot?.LBLI0109}</Link> {labelsIot?.LBLI0110} <Link target='_blank' to="/privacy-policy">{labelsIot?.LBLI0111}</Link>
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </PR.TabPanel>
       {/* {false && <PR.TabPanel headerTemplate={paymentPaypal}>
          <div className="card-section">
            <PaypalPayment
              stateBundle={state?.bundle}
              dispalyPrice={setNumberPrecision(price)}
            />
          </div>
        </PR.TabPanel>}
        {paymentOptions?.googlePay && (
          <PR.TabPanel headerTemplate={paymentGPay}>
            <div className="card-section">
              <AppleOrGooglePay
                options={prOptions(price)}
                paymentGateway="GooglePay"
                id="googlePay"
                clientSecret={sessionClientSecret}
                confirmPaymentHandler={confirmPaymentHandler}
              />
            </div>
          </PR.TabPanel>
        )}
        {paymentOptions?.applePay && (
          <PR.TabPanel headerTemplate={paymentApplePay}>
            <div className="card-section">
              <AppleOrGooglePay
                options={prOptions(price)}
                paymentGateway="ApplePay"
                id="applePay"
                clientSecret={sessionClientSecret}
                confirmPaymentHandler={confirmPaymentHandler}
              />
            </div>
          </PR.TabPanel>
        )} */}
      </PR.TabView>
    </>
  );
};

export default PaymentOptions;
