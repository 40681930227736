import { logout } from "../../store/auth.slice";

export const logoutHandler = (dispatch) => {
  dispatch(logout());
};

export const cardNumberstyles = {
  iconColor: '#666EE8',
  color: '#495057',
  fontWeight: 400,
  fontSize: '15px',

  '::placeholder': {
      color: '#b2b0b0',
      fontSize: '14px',
      fontWeight: 400,
  },
}

export const cardElementstyles = {
  iconColor: '#666EE8',
  color: '#495057',
  lineHeight: '40px',
  fontWeight: 400,
  fontSize: '15px',

  '::placeholder': {
      color: '#b2b0b0',
      fontSize: '14px',
      fontWeight: 400,
  },
}