import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { PrivateRoute, PublicRoute } from "./customRoute";
import SignUp from "../pages/auth/signup/SignUp";
import Signin from "../pages/auth/signin/Signin";
import Plans from "../pages/plans/Plans";
import Payment from "../pages/payment/Payment";
import NotFoundPage from "../pages/404";
import Dashboard from "../pages/dashboard/Dashboard";
import ForgotPassword from "../pages/auth/forgot-password/ForgotPassword";
import ResetPassword from "../pages/auth/reset-password/ResetPassword";
import VerifyEmail from "../pages/auth/verify-email/VerifyEmail";
import PaymentSuccess from "../pages/payment/PaymentSuccess";
import TermsAndConditionsPage from "../pages/help/TermsAndConditionPage";
import PrivacyPolicyPage from "../pages/help/PrivacyPolicyPage";

const Navigation = () => {
  return (
    <Router>
      <Routes>
        {/* Public Route */}
        <Route element={<PublicRoute />}>
          <Route path="/" element={<Signin />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>

        {/* Private Route */}
        <Route element={<PrivateRoute />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/payment-success" element={<PaymentSuccess />} />
          <Route path="/plans" element={<Plans />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>

        {/* 404 Page */}
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      </Routes>
    </Router>
  );
};

export default Navigation;
