import React from "react";
import * as PR from "../../prime-modules/index";
import { envConfig } from "../../constants";
import { GoogleIcon } from "../../assets/images";

const GoogleLogin = ({title, iccidCheck, screen}) => {

  const login = () => {
    if ((iccidCheck && iccidCheck()) || screen ==="signin") {
      const scope = "profile email";
      window.location.href = `https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=${
        envConfig.REACT_APP_GOOGLE_AUTH_CLIENT_KEY
      }&redirect_uri=${
        envConfig.REACT_APP_GOOGLE_CALLBACK_URL
      }&scope=${encodeURIComponent(scope)}`;
    }
  };

  return (
    <PR.Button onClick={login} type={"button"} label={title} className="social-button">
      <PR.Image src={GoogleIcon} alt="Mail" className="google" />
    </PR.Button>
  )
};

export default GoogleLogin;
