import { useEffect, useState } from "react";

const useDebounce = (val, delay)=>{
    const [ debounceVal, setDebounceVal ] = useState(val);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebounceVal(val);
        }, delay);

        return () => {
            clearTimeout(handler);
        }
    }, [val]);

    return debounceVal.trim()
    
}

export default useDebounce