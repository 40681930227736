import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import "./index.scss";
import * as PR from "../../prime-modules/index";
import { data2goIcon, dashboardIcon1, dashboardIcon3, dashboardIcon4, dashboardIcon5, dashboardIcon6, } from "../../assets/images";
import DevicesList from "./DevicesList";
import PaymentReceipt from "./PaymentReceipt";
import Settings from './Settings';
import TermsAndConditions from './TermsAndConditions';
import PrivacyPolicy from './PrivacyPolicy';
import FAQ from './FAQ';
import { useTranslationContext } from '../../context/TranslationContext';
import { getlanguageAPI, globalConfig } from "../../services/api";
import { useTranslation } from 'react-i18next';
import { setLanguageIntial } from '../../store/language.slice';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';

const Dashboard = () => {
  const [selectedSection, setSelectedSection] = useState('devices');
  const { labelsIot } = useTranslationContext();
  const { i18n } = useTranslation();
  const dispatch = useDispatch()
  const language = i18n.language.split('-')[0];
  const [isVisible, setIsVisible] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 962);

  useQuery(['home', language], getlanguageAPI.bind(null, language, "labelsIot"), {
    retry: 1,
    onSuccess: o => dispatch(setLanguageIntial({ k: language, v: o }))
  });

  useQuery(['error',language],getlanguageAPI.bind(null,language,"validationsIot"),{
    retry:1,
    onSuccess: o => dispatch(setLanguageIntial({k:language,v:o}))
  });

  useQuery(['message',language],getlanguageAPI.bind(null,language,"messageCatalog"),{
    retry:1,
    onSuccess: o => dispatch(setLanguageIntial({k:language,v:o}))
  });

  const menuItems = [
    { id: 'devices', icon: dashboardIcon1, alt: 'Devices', tooltip: labelsIot?.LBLI0019 },
    { id: 'payment', icon: dashboardIcon3, alt: 'Payment', tooltip: labelsIot?.LBLI0068 },
  ];

  const endMenuItems = [
    { id: 'faq', icon: dashboardIcon5, alt: 'FAQ', tooltip: labelsIot?.LBLI0121 },
    { id: 'support', icon: dashboardIcon6, alt: 'Contact support', tooltip: labelsIot?.LBLI0088 },
    { id: 'settings', icon: dashboardIcon4, alt: 'Settings', tooltip: labelsIot?.LBLI0080 },
  ];

  const handleMenuClick = (section) => {
    setSelectedSection(section);
    setIsVisible(!isVisible);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 962);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleDiv = () => {
    setIsVisible(!isVisible);
  };

  const start = (
    <div className='menu-block'>
      <div>
        <PR.Image
          onClick={() => setSelectedSection("devices")}
          src={data2goIcon}
          alt="data2go IOT Logo"
          className="logo-img mobile-hide"
        />
        <ul>
          {menuItems.map((item) => (
            <li
              key={item.id}
              onClick={() => handleMenuClick(item.id)}
              className={selectedSection === item.id ? 'active' : ''}
            >
              <PR.Image
                src={item.icon}
                alt={item.alt}
                className="menu-icon custom-image-tooltip"
                data-pr-tooltip={item.tooltip}
                data-pr-position="right"
                data-pr-at="right+5 top"
                data-pr-my="left center-1"
              />
              <span className='menu-label'>{item.alt}</span>
            </li>
          ))}
        </ul>
      </div>
      <ul className="card mb-2">
        {endMenuItems.map((item) => (
          <li
            key={item.id}
            className={selectedSection === item.id ? 'active' : ''}
            {...(item.id !== 'support' && { onClick: () => handleMenuClick(item.id) })}>
            {item.id === 'support' ? (
              <Link to={`mailto:${globalConfig.supportEmail}`} rel="noopener noreferrer">
                <PR.Image
                  src={item.icon}
                  alt={item.alt}
                  className="menu-icon custom-image-tooltip"
                  data-pr-tooltip={item.tooltip}
                  data-pr-position="right"
                  data-pr-at="right+5 top"
                  data-pr-my="left center-1"
                />
                 <span className='menu-label'>{item.alt}</span>
              </Link>
            ) : (
              <>
              <PR.Image
                src={item.icon}
                alt={item.alt}
                className="menu-icon custom-image-tooltip"
                data-pr-tooltip={item.tooltip}
                data-pr-position="right"
                data-pr-at="right+5 top"
                data-pr-my="left center-1"
              />
              <span className='menu-label'>{item.alt}</span>
              </>
            )}
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <>
      <PR.Tooltip target=".custom-image-tooltip" />
      <section className="dashboard-main">
        <div className="dashboard-wrapper">
          <div className="sidebar-block">

            {/* Visible only on mobile */}
            {isMobileView && (
              <div className='mobile-nav'>
                <div className='flex align-items-center gap-4'>
                <PR.Image
                  onClick={() => setSelectedSection("devices")}
                  src={data2goIcon}
                  alt="data2go IOT Logo"
                  className="logo-img"
                />
                <h2>Dashboard</h2>
                </div>
                <PR.Button onClick={toggleDiv} className='menu-bar-icon'>
                  <i className={isVisible ? 'pi pi-times' : 'pi pi-bars'}></i>
                  {/* <i className="pi pi-bars"></i> */}
                </PR.Button>
              </div>
            )}

            {/* Visible only on desktop */}
            {!isMobileView && (
              <PR.Menubar start={start} className="side-navbar" />
            )}

            {/* Mobile Menubar Toggle */}
            {isVisible && isMobileView && (
              <PR.Menubar start={start} className="side-navbar" />
            )}
          </div>
          <div className="content-block">
            {selectedSection === 'devices' && <DevicesList />}
            {selectedSection === 'payment' && <PaymentReceipt setSelectedSection={setSelectedSection} />}
            {selectedSection === 'settings' && <Settings setSelectedSection={setSelectedSection} />}
            {selectedSection === 'termsandconditions' && <TermsAndConditions setSelectedSection={setSelectedSection} />}
            {selectedSection === 'privacypolicy' && <PrivacyPolicy setSelectedSection={setSelectedSection} />}
            {selectedSection === 'faq' && <FAQ setSelectedSection={setSelectedSection} />}
          </div>
        </div>
        <div className="dashboard-footer">
          <p>©{new Date().getFullYear()} {labelsIot?.LBLI0124} | {labelsIot?.LBLI0125} <Link target='_blank' to={'https://www.bluehouse-technology.co.uk/'}>{labelsIot?.LBLI0126}</Link> & <Link target='_blank' to={'https://www.cobira.co/'}>{labelsIot?.LBLI0146}</Link></p>
        </div>
      </section>
    </>
  );
};

export default Dashboard;
