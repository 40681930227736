import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { cardElementstyles, cardNumberstyles } from "./helper";
import * as PR from "../../prime-modules/index";
import { useTranslationContext } from "../../context/TranslationContext";
import { useToast } from "../../context/ToastContext";
import { useMutation } from "react-query";
import { changeSubscriptionPaymentMethodAPI } from "../../services/api";

const PaymentSettings = ({
  setStripePayError,
  stripePayError,
  setShowPaymentMethod,
  iccid,
}) => {
  const { labelsIot } = useTranslationContext();
  const { showToast } = useToast();
  const stripe = useStripe();
  const elements = useElements();
  const onCardChange = (e) => {
    e.error ? setStripePayError(e.error.message) : setStripePayError("");
  };

  const { mutate, isLoading } = useMutation(
    changeSubscriptionPaymentMethodAPI,
    {
      onSuccess: () => {
        setShowPaymentMethod(false);
        showToast("success", labelsIot?.LBLI0149);
      },
      onError: (err) => {
        showToast("error", err?.message);
      },
    }
  );

  if (!elements && !stripe) {
    return null;
  }

  const changePaymentmehtodHandler = () => {
    const cardNumberElement = elements.getElement(CardNumberElement);

    stripe
      .createPaymentMethod({
        type: "card",
        card: cardNumberElement,
      })
      .then(function (result) {
        if (result.error) {
          setStripePayError(result.error.message);
        } else {
          const paymentMethodId = result.paymentMethod.id;
          if (paymentMethodId) {
            const request = {
              paymentMethodId,
            };
            mutate({ request, iccid });
          } else {
            showToast("error", "PMTMSG-04");
          }
        }
      });
  };

  return (
    <div className="grid payment-card-section">
      <div className="col-12">
        <p>{labelsIot?.LBLI0151}</p>
      </div>
        <div className="col-12 mb-2">
          <div className="payment-label">
            <div className="label">{labelsIot?.LBLI0106}</div>
            <CardNumberElement
              className="card-input card-number"
              options={{ style: { base: cardNumberstyles } }}
              onChange={onCardChange}
            />
          </div>
        </div>
      <div className="col-12 lg:col-6 md:col-6">
        <div className="payment-label">
          <CardExpiryElement
            className="card-input card-month"
            options={{ style: { base: cardElementstyles } }}
            onChange={onCardChange}
          />
        </div>
      </div>
      <div className="col-12 lg:col-6 md:col-6">
        <div className="payment-label">
          <CardCvcElement
            className="card-input card-month"
            options={{ style: { base: cardElementstyles } }}
            onChange={onCardChange}
          />
        </div>
      </div>
      <div className="err-msg">{stripePayError}</div>
      <div className="col-12">
        <PR.Button
          label={labelsIot?.LBLI0152}
          type="submit"
          className="device-confirm-button w-full mt-2"
          disabled={!!stripePayError || isLoading}
          onClick={changePaymentmehtodHandler}
        />
      </div>
    </div>
  );
};

export default PaymentSettings;
