import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import i18n from 'i18next';
import { getlanguageAPI } from '../services/api';
import { setLanguageIntial, setLanguageStore } from '../store/language.slice';

// Reusable language handling hook
export const useLanguage = (languageAssert, language) => {
  const dispatch = useDispatch();

  // Callback to change language
  const changeLanguage = useCallback((lang) => {
    const selectedLanguage = lang.value.code.toLowerCase();
    i18n.changeLanguage(selectedLanguage);
  }, []);

  // Update Redux store with language data if available
  useEffect(() => {
    if (languageAssert[language]) {
    dispatch(setLanguageStore(languageAssert[language]));
    }
  }, [language]);

  useQuery(['home',language],getlanguageAPI.bind(null,language,"labelsIot"),{
    enabled:!languageAssert[language],
    retry:1,
    onSuccess: o => dispatch(setLanguageIntial({k:language,v:o}))
  });
  useQuery(['error',language],getlanguageAPI.bind(null,language,"validationsIot"),{
    enabled:!languageAssert[language],
    retry:1,
    onSuccess: o => dispatch(setLanguageIntial({k:language,v:o}))
  });
  useQuery(['message',language],getlanguageAPI.bind(null,language,"messageCatalog"),{
    enabled:!languageAssert[language],
    retry:1,
    onSuccess: o => dispatch(setLanguageIntial({k:language,v:o}))
  });
  
  


  return {
    changeLanguage,
  };
};
