import React, { useEffect, useState } from "react";
import "./index.scss";
import { useTranslationContext } from "../../context/TranslationContext";
import * as PR from "../../prime-modules/index";
import useDebounce from "../../hooks/useDebounce";

const tabs = [
  {
    header: 'Dolor sit amet consectetur. Volutpat non arcu tellus malesuada?',
    children: <p className="m-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
  },
  {
    header: 'Lorem Ipsum has been the industrys standard dummy text ever since the 1500s?',
    children: <p className="m-0"> It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
  },
  {
    header: 'Dolor sit amet consectetur. Volutpat non arcu tellus malesuada?',
    children: <p className="m-0"> Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
  },
  {
    header: 'Dolor sit amet consectetur. Volutpat non arcu tellus malesuada?',
    children: <p className="m-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages.</p>
  },
  {
    header: 'Dolor sit amet consectetur. Volutpat non arcu tellus malesuada?',
    children: <p className="m-0"> Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
  },
  {
    header: 'Dolor sit amet consectetur. Volutpat non arcu tellus malesuada?',
    children: <p className="m-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages.</p>
  },
  {
    header: 'Dolor sit amet consectetur. Volutpat non arcu tellus malesuada?',
    children: <p className="m-0"> Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
  },
  {
    header: 'Dolor sit amet consectetur. Volutpat non arcu tellus malesuada?',
    children: <p className="m-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages.</p>
  },
  {
    header: 'Dolor sit amet consectetur. Volutpat non arcu tellus pellentesque?',
    children: <p className="m-0"> It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
  },
  {
    header: 'Dolor sit amet consectetur. Volutpat non arcu tellus malesuada?',
    children: <p className="m-0">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>
  }
];

const FAQ = () => {
  const { labelsIot } = useTranslationContext();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [sortList, setSortList] = useState(tabs)

  const searchValue = useDebounce(searchKeyword, 1000);

  useEffect(() => {
    if(searchValue.trim().length === 0){
      setSortList(tabs)
      return
    }
    let sortFaqList = sortList?.filter(item=> item.header.toLowerCase().includes(searchValue.toLowerCase()) || item?.children?.props?.children.toLowerCase().includes(searchValue.toLowerCase())
    )
    setSortList(sortFaqList)
  }, [searchValue])

  const splitFaqTabs = () => {
    const midpoint = Math.ceil(sortList.length / 2);
    const leftTabs = sortList.slice(0, midpoint);
    const rightTabs = sortList.slice(midpoint);

    return {
      leftTabs: leftTabs.map((tab, i) => (
        <PR.AccordionTab key={`left-${i}`} header={tab.header}>
          {tab.children}
        </PR.AccordionTab>
      )),
      rightTabs: rightTabs.map((tab, i) => (
        <PR.AccordionTab key={`right-${i}`} header={tab.header}>
          {tab.children}
        </PR.AccordionTab>
      ))
    };
  };

  const { leftTabs, rightTabs } = splitFaqTabs();

  return (
    <>
      <div className="add-device">
        <div className="content-header flex align-items-center justify-content-between">
          <h1>{labelsIot?.LBLI0137}</h1>
          <div className="search">
            <PR.Button icon="pi pi-search" className="search-button" />
            <PR.InputText
              onChange={(e) => setSearchKeyword(e.target.value)}
              placeholder={labelsIot?.LBLI0021}
            />
          </div>
        </div>
       {!!sortList && sortList?.length !== 0 ?<div className="settings-section faq-section">
          <div className="grid grid-nogutter w-12">
            <div className="col-12 md:col-6 left-block">
              <PR.Accordion>{leftTabs}</PR.Accordion>
            </div>
            <div className="col-12 md:col-6 right-block">
              <PR.Accordion>{rightTabs}</PR.Accordion>
            </div>
          </div>
        </div>:<div className="empty-secton faq-empty-secton">
          <p>{labelsIot?.LBLI0138}</p>
        </div>}
      </div>
    </>
  );
};

export default FAQ;