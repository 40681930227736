import * as Yup from "yup";


    // const userNameSchema =
    // Yup.string()
    //   .trim()
    //   .required('VLDN0035')
    //   .matches(/^[A-Za-z]+$/, 'VLDN0034')
    //   .min(3, 'VLDN0036')
    //   .max(20, 'VLDN0036');

      // const firstNameSchema =
      // Yup.string()
      //   .required('VLDN0007')
      //   .min(3, 'VLDN0008')
      //   .max(20, 'VLDN0008');

        const fullNameSchema =
        Yup.string()
          .required('VLDN0040')
          .min(3, 'VLDN0041')
          .max(30, 'VLDN0041');
    
    //  const lastNameSchema =
    //   Yup.string()
    //     .required('VLDN0009')
    //     .min(3, 'VLDN0010')
    //     .max(20, 'VLDN0010');

        const deviceNameSchema =
        Yup.string()
          .trim()
          .required('VLDN0038')

          // const deviceTypeSchema =  Yup.object().shape({
          //   name: Yup.string()
          //     .required('VLDN0039'),
          // });
          

            const iccidSchema =
            Yup.string()
              .trim()
              .required('VLDN0037')
              .min(18, 'VLDN0042')

 const emailSchema =
  Yup.string()
    .trim()
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,})$/,
      'VLDN0006'
    )
    .email('VLDN0006')
    .required('VLDN0005');
  
 const passwordSchema =
  Yup.string()
    .trim()
    .required('VLDN0001')
    .min(7, 'VLDN0002')
    .max(20, 'VLDN0002')
    .matches(/\d/, 'VLDN0002')
    .matches(/[A-Za-z]/, 'VLDN0002')
    .matches(/^\S*$/, 'VLDN0020')
    .strict(true);
 const confirmPasswordSchema =
  Yup.string()
    .trim()
    .required('VLDN0003')
    .matches(/^\S*$/, 'VLDN0020')
    .when("password", (password, schema) => {
      return password
        ? schema.oneOf([Yup.ref("password")], 'VLDN0004')
        : schema;
    });

export const signUpValidationSchema = Yup.object().shape({
    firstName:fullNameSchema,
    email: emailSchema,
    password: passwordSchema
});

export const cardPaymentSchema = Yup.object().shape({
  email: emailSchema,
});

export const freepaymentSchema = Yup.object().shape({
  email: emailSchema
});

export const siginValidationSchema = Yup.object().shape({
  email: emailSchema,
    password: passwordSchema,
});

export const resetValidationSchema = Yup.object().shape({
    password: passwordSchema,
    confirmPassword: confirmPasswordSchema
});
export const otpValidateSchema = Yup.object().shape({
  otp: Yup.string().trim()
    .required('VLDN0022')
    .min(6, 'VLDN0023')
    .max(6, 'VLDN0023')
    .matches(/^[0-9\b]+$/, 'VLDN0024')
});
export const forgotPasswordValidationSchema = Yup.object().shape({
  email: emailSchema,
});

export const newDeviceValidationSchema = Yup.object().shape({
    tag: deviceNameSchema,
    //simIconName: deviceTypeSchema,
    iccid:iccidSchema
});


export const profileValidationSchema = Yup.object().shape({
  firstName: fullNameSchema,
  email: emailSchema,
});

export const changePasswordValidationSchema = Yup.object().shape({
  password: passwordSchema,
  confirmPassword: confirmPasswordSchema
});